import React, { useContext, useEffect, useState } from 'react'
import SbEditable from 'storyblok-react'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { Box } from '@material-ui/core'
import ResourceCard from '@system/resource-card'
import PageContext from '@context'
import get from 'lodash/get'

const useStyles = makeStyles((theme) => ({
  displayCards: (props) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: !props.isRelatedContent && '20px 12px',
    textAlign: props.alignment === 'left' ? '' : `${props.alignment}`,
    width: props.isRelatedContent ? '100%' : '31%',
    [theme.breakpoints.down('sm')]: {
      width: props.isRelatedContent ? '100%' : '50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100% !important',
    },
    '&:first-child': {
      '&>div': {
        position: !props.isRelatedContent && 'relative',
        '&:after': {
          content: !props.isRelatedContent && '"LATEST"',
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.text.white,
          height: !props.isRelatedContent && '24px',
          width: '100%',
          backgroundColor:
            !props.isRelatedContent && theme.palette.background.limerick,
          position: !props.isRelatedContent && 'absolute',
          textAlign: 'center',
          top: 0,
          left: 0,
          display: 'block',
          padding: '3px 0px',
        },
      },
    },
  }),
  cardContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    margin: 'auto',
    flex: 1,
    maxWidth: '100%',
  },
}))

const PodcastReferences = (props) => {
  const classes = useStyles({
    ...props.blok,
    isRelatedContent: props.isRelatedContent,
  })
  const pageContext = useContext(PageContext)
  const {
    isReferencingAllPublishedPodcasts,
    numberOfReferences,
    podcasts,
    sortBy,
  } = props.blok

  const [filteredPodcastStories, setFilteredPodcastStories] = useState([])

  const allPodcasts = (
    isReferencingAllPublishedPodcasts
      ? pageContext.podcasts && pageContext.podcasts
      : podcasts && podcasts
  )
    .filter((podcast) => podcast.content.podcastTitle !== props.podcastTitle)
    .map((podcast) => ({ ...podcast, ...podcast.content }))
    .reverse()

  const getNumberOfReferences = props.isRelatedContent
    ? 1
    : numberOfReferences || (!!allPodcasts && allPodcasts.length)

  const compare = (firstObj, secondObj) => {
    firstObj = get(
      allPodcasts.filter((story) => story.name === firstObj.name),
      '[0]'
    )
    secondObj = get(
      allPodcasts.filter((story) => story.name === secondObj.name),
      '[0]'
    )

    if (firstObj && secondObj) {
      let comparison = 0
      if (sortBy === 'none') {
        comparison = 0
      } else {
        const nameA =
          sortBy === 'alphabetical'
            ? firstObj.name
            : sortBy === 'chronological' || sortBy === 'reverse-chronological'
            ? new Date(firstObj.first_published_at || firstObj.created_at)
            : null

        const nameB =
          sortBy === 'alphabetical'
            ? secondObj.name
            : sortBy === 'chronological' || sortBy === 'reverse-chronological'
            ? new Date(secondObj.first_published_at || secondObj.created_at)
            : null

        if (sortBy === 'reverse-chronological') comparison = nameB - nameA
        else if (sortBy === 'chronological') comparison = nameA - nameB
        else if (nameA > nameB) comparison = 1
        else if (nameA < nameB) comparison = -1
      }
      return comparison
    }
  }

  useEffect(() => {
    allPodcasts &&
      allPodcasts.length > 0 &&
      setFilteredPodcastStories(allPodcasts.sort(compare))
  }, [sortBy, pageContext.podcasts])

  return (
    <SbEditable content={props.blok}>
      <Box className={classes.cardContainer}>
        {filteredPodcastStories &&
          filteredPodcastStories
            .slice(0, getNumberOfReferences)
            .map((podcast, index) => {
              const {
                subtitle,
                image,
                buttonAssetUrl,
                format,
                full_slug,
                podcastTitle,
              } = podcast
              const podcastBlokData = {
                alignment: 'left',
                description: subtitle,
                image: image,
                noDescMargin: false,
                notVisibleOnMobile: false,
                title: podcast.title,
                resourceReference: true,
                buttonAssetUrl: buttonAssetUrl,
                format: format,
                link: full_slug,
                episodeNumber: podcastTitle,
              }

              return (
                <Box
                  className={classNames(classes.displayCards)}
                  key={podcast._uid}
                >
                  <ResourceCard
                    blok={{
                      ...podcastBlokData,
                      textToHyperlink: 'LISTEN',
                      isPodcastModule: true,
                    }}
                  />
                </Box>
              )
            })}
      </Box>
    </SbEditable>
  )
}

export default PodcastReferences
